import request from '../../utils/axios';

var Api = ''
if (location.origin.indexOf('localhost') > -1) {
    Api = 'https://testwww.rlc525.com/api/proxy'
} else {
    Api = window.location.origin + '/api/proxy';
}

//获取议息活动时间
export function getYiXiTime(data) {
    return request({
        url: Api + '/v4/A176',
        method: 'POST',
        data,
        headers: {
            'Content-Type': 'application/json'
        },
    })
}